
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

form {
  width: 100%;
}

label {
  &.required:after {
    content: "*";
    color: $danger;
    margin-left: 5px;
  }
}

h1 {
  font-size: 2.5em;
  margin: 1em 0;
}

h2 {
  font-size: 1.5em;
  margin: 1em 0;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.2em;
}

h46 {
  font-size: 1.2em;
}