.navbar {
  padding: 0.75rem 0;
  .navbar-brand {
    padding: 0;
    font-size: 1rem;
    .logo {
      width: 60px;
      height: auto;
    }
  }
}