html, body, div#root {
  background-color: #efefef;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

header.top-navigation {
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf;
}

div.main-container,
aside.sidebar,
section.content {
  height: 100%;
  position: relative;
}

div.main-container {
  display: flex;
  flex-wrap: nowrap;

  aside.sidebar,
  section.content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  aside.sidebar {
    flex-basis: 300px; /* width/height  - initial value: auto */

    a:not(.disabled) {
      text-decoration: none;
      color: white;
    }
  }
}

.sign-up {
  max-width: 500px;
}

.pane-container {
  background-color: white;
  border-radius: 5px;
}